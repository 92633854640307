import 'styles/global.scss';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { type Datastore } from '@youversion/analytics-types';
import { PagesRouterAnalytics } from '@youversion/analytics-youversion-nextjs';
import { AuthProvider } from '@youversion/auth';
import { InitializeAuth } from 'components/app/initialize_auth';
import { Layout } from 'components/ui/layout';
import { AlertProvider } from 'context/alert';
import { LazyMotion } from 'framer-motion';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { appWithTranslation, i18n } from 'next-i18next';
import { setCookie } from 'nookies';
import React, { useEffect, useMemo, useState } from 'react';
import { apiEnv, COMMIT_SHA, DATAMAN_URL, oauthCredentials } from 'utils/constants';
import { setDateFnsLocale } from 'utils/helpers/dateLocale';

const loadFeatures = () => import('src/components/app/framer_features').then(res => res.default);

interface NextAppProps extends Pick<AppProps, 'pageProps' | 'Component'> {}

const App = ({ Component, pageProps }: NextAppProps) => {
  const dir = i18n?.dir() ?? 'ltr';
  const language = i18n?.resolvedLanguage;

  const router = useRouter();

  const analyticsConfig: Datastore.Config = useMemo(() => {
    return {
      applicationId: 'com.bible.give',
      appVersion: COMMIT_SHA,
      baseDomain: 'bible.com',
      clientId: oauthCredentials.clientId,
      debug: false,
      disableDefaultForegroundEvent: Boolean(router.query.hide_nav),
      disableDefaultListeners: Boolean(router.query.hide_nav),
      disableDefaultNonIdleTracking: Boolean(router.query.hide_nav),
      disableDefaultPageImpressions: Boolean(router.query.hide_nav),
      endpoint: DATAMAN_URL,
      environment: apiEnv,
      localeFallback: i18n?.language ?? null,
      relatedDomains: ['youversion.com', 'bible.com'],
    };
  }, [router.query.hide_nav]);

  useEffect(() => {
    if (language && dir) {
      setDateFnsLocale(language);
      document.dir = dir;
    }
  }, [dir, language]);

  useEffect(() => {
    if (language) {
      setCookie(null, 'locale', language);
    }
  }, [language]);

  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            refetchOnReconnect: false,
            refetchOnWindowFocus: false,
          },
        },
      })
  );

  useEffect(() => {
    const elm = document.getElementsByTagName('html')[0];
    elm.style.removeProperty('background-color');
  }, []);

  useEffect(() => {
    const onPageLoad = () => {
      if (window.FlutterOnPageLoad) {
        window.FlutterOnPageLoad.postMessage('pageLoaded');
      }
    };
    if (document.readyState === 'complete') {
      onPageLoad();
    } else {
      window.addEventListener('load', onPageLoad, false);
      return () => window.removeEventListener('load', onPageLoad);
    }
  }, []);

  return (
    <React.StrictMode>
      <Head>
        <title>YouVersion Giving</title>
        <meta content='minimum-scale=1, maximum-scale=5, initial-scale=1, width=device-width' name='viewport' />
      </Head>
      <PagesRouterAnalytics config={analyticsConfig} />
      <QueryClientProvider client={queryClient}>
        <AlertProvider>
          <AuthProvider config={oauthCredentials}>
            <InitializeAuth>
              <Layout>
                <LazyMotion features={loadFeatures} strict={true}>
                  {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                  <Component {...pageProps} />
                </LazyMotion>
              </Layout>
            </InitializeAuth>
          </AuthProvider>
        </AlertProvider>
      </QueryClientProvider>
    </React.StrictMode>
  );
};

export default appWithTranslation(App);
